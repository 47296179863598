<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center">
            <div v-if="warehouse" class="mb-2" style="font-size: 20px;">
                {{ $t('general.warehouse') }} <span style="font-weight: bold;"> {{ this.warehouse.name }} </span>
            </div>

            <div class="w-full d-flex justify-content-end">
                <BFormCheckbox @change="loadData" v-model="currentDayStock" switch>{{$t('config.warehouses.stock_in_real_time')}}</BFormCheckbox>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="ingredients"/>
        </b-card>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import {BCard, BFormCheckbox, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import {getMoneyTypes, getPieceTypes, getVolumeTypes, getWeightTypes} from '@/libs/constants/ingredients.js'


    export default {
        components: {
            Table,
            BFormCheckbox,
            BCard, 
            BOverlay
        },
        data() {
            return {
                currentDayStock: true,
                warehouse: null,
                ingredients: [],
                showLoader: false,
                warehouse_id: ''
            }
        },
        computed:{
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            columnDefs() {
                const hasPermission = this.$hasPermission(this.$permissions.ConfigurationWrite)

                return [
                    {
                        headerName: this.$t('table_fields.ingredient_code'),
                        field: 'ingredient_code',
                        filter: true,
                        editable: false
                    },
                    {headerName: this.$t('table_fields.name'), field: 'name', filter: true, editable: false},
                    {
                        headerName: this.$t('table_fields.quantity'),
                        field: 'quantity',
                        filter: true,
                        cellRenderer: (params) => this.getQuantityFromStock(params.data),
                        cellStyle: {textAlign: 'center'},
                        editable: hasPermission
                    },
                    {
                        headerName: this.$t('table_fields.packaging'),
                        field: 'packaging_with_unit',
                        filter: true,
                        cellRenderer: (params) => this.getPackagingValues(params.data),
                        cellStyle: {textAlign: 'center'},
                        editable: hasPermission
                    },
                    {
                        headerName: this.$t('table_fields.stock'),
                        field: 'total_stock_units',
                        filter: true,
                        cellRenderer: (params) => this.getPackagingNamesStock(params.data),
                        cellStyle: {textAlign: 'center'},
                        editable: hasPermission
                    },
                    {
                        headerName: this.$t('table_fields.price_per_packaging'),
                        field: 'average_price',
                        filter: false,
                        cellRenderer: (params) => this.getStockPrice(params.data),
                        cellStyle: {textAlign: 'center'},
                        editable: hasPermission
                    },
                    {
                        headerName: this.$t('table_fields.total'),
                        field: 'average_price_total',
                        filter: true,
                        cellRenderer: (params) => this.getStockPriceTotal(params.data),
                        cellStyle: {textAlign: 'center'},
                        editable: hasPermission
                    }
                ]
            }
        },
        methods: {
            getStockPriceTotal(item) {
                if (!item.average_price) {
                    return '/'
                }

                let type = null
                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                }  else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }

                const totalStockConverted = item.total_stock / type.defaultConverted

                return `${this.$prettyNumberRounding(item.average_price * totalStockConverted, 6)  }€`
            },
            getStockPrice(item) {
                if (!item.average_price) {
                    return '/'
                }

                let type = null
                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                }  else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }

                if (!type) return '/'

                return `${this.$prettyNumberRounding(item.average_price * item.packaging, 6)  }€`
            },
            getQuantityFromStock(item) {
                if (!item || item.total_stock === undefined || item.total_stock === null) return '/'

                let type = null
                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }

                if (!type) {
                    return
                }

                const quantity = Number(Number(item.total_stock) / Number(item.packaging)).toFixed(2).replace('.00', '')

                return `${quantity  }x`
            },
            getPackagingNamesStock(item) {
                if (!item || item.total_stock === undefined || item.total_stock === null) return '/'

                let type = null
                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                }  else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }


                if (!type) {
                    return
                }
                const allowedDecimals = Math.log10(type.factor)
                const stock = Number(Number(item.total_stock) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return `${stock } ${  item.packaging_unit}`
            },
            getPackagingValues(item) {
                if (!item) return '/'

                let type = null
                if (item.packaging_unit === 'mL' || item.packaging_unit === 'dL' || item.packaging_unit === 'L') {
                    type = this.volume_types.find(ele => ele.name === item.packaging_unit)
                } else if (item.packaging_unit === 'g' || item.packaging_unit === 'dag' || item.packaging_unit === 'kg') {
                    type = this.weight_types.find(ele => ele.name === item.packaging_unit)
                }  else if (item.packaging_unit === 'EUR') {
                    type = this.money_types.find(ele => ele.name === item.packaging_unit)
                } else {
                    type = this.piece_types.find(ele => ele.name === item.packaging_unit)
                }


                if (!type) return '/'

                const allowedDecimals = Math.log10(type.factor)
                const packaging = Number(Number(item.packaging) / Number(type.factor)).toFixed(allowedDecimals).replace(`.${  '0'.repeat(allowedDecimals)}`, '')

                return `${packaging  } ${  item.packaging_unit}`
            },
            async loadData() {
                try {
                    this.showLoader = true

                    let url = `/api/client/v1/warehouses/stock/${this.warehouse_id}`

                    if (this.currentDayStock) {
                        url = `/api/client/v1/warehouses/stock/current/${this.warehouse_id}`
                    }

                    const response = await this.$http.get(url)
                    this.warehouse = response.data
                    if (this.warehouse && this.warehouse.ingredients && this.warehouse.ingredients.length > 0) {
                        this.ingredients = this.warehouse.ingredients
                    }
                    
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            }
        },
        mounted() {
            this.warehouse_id = this.$route.params.warehouse_id
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
